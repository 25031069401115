<template>
    <div>
        <!-- barAnimate -->
        <b-row>
            <b-col md="12">
                <b-form-group>
                    <span>Animate entry/exit</span>
                    <b-form-checkbox name="barAnimate" class="float-right" switch stacked v-model="selectedValuebarAnimate" />
                </b-form-group>
            </b-col>

            <!-- wiggleButton -->
            <b-col md="12">
                <b-form-group>
                    <span>Wiggle button</span>
                    <b-form-checkbox name="wiggleButton" class="float-right" switch stacked v-model="selectedValuewiggleButton" />
                </b-form-group>
            </b-col>

            <!-- allowHideBar -->
            <b-col md="12">
                <b-form-group>
                    <span>Allow to hide bar</span>
                    <b-form-checkbox name="allowHideBar" class="float-right" switch stacked v-model="selectedValueAllowHideBar" />
                </b-form-group>
            </b-col>

            <!-- pushesPageDown -->
            <b-col md="12">
                <b-form-group>
                    <span>Pushes page down</span>
                    <b-form-checkbox name="pushesPageDown" class="float-right" switch stacked v-model="selectedValuePushesPageDown" />
                </b-form-group>
            </b-col>

            <!-- remainsInPlace -->
            <b-col md="12">
                <b-form-group>
                    <span>Remains in place</span>
                    <b-form-checkbox name="remainsInPlace" class="float-right" switch stacked v-model="selectedValueRemainsInPlace" />
                </b-form-group>
            </b-col>

            <!-- barPlacement -->
            <b-col md="12">
                <b-form-group label="Placement" label-for="barPlacement">
                    <Select2 name="barPlacement" :options="this.barPlacementOptions" v-model="selectedValues.barPlacement" />
                </b-form-group>
            </b-col>

            <!-- barSize -->
            <b-col md="12">
                <b-form-group label="Bar Size" label-for="barSize">
                    <Select2 name="barSize" :options="this.barSizeOptions" v-model="selectedValues.barSize" />
                </b-form-group>
            </b-col>

            <!-- barDisplay -->
            <b-col md="12">
                <b-form-group label="When does it display?" label-for="barDisplay">
                    <Select2 name="barDisplay" :options="this.barDisplayOptions" v-model="selectedValues.barDisplay" />
                </b-form-group>
            </b-col>

            <!-- animation_effect -->
            <b-col md="12">
                <b-form-group label="Animated Effect" label-for="animation_effect">
                    <b-form-select name="animation_effect" :options="this.animationEffectOptions" v-model="selectedValues.animation_effect" />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup, BFormCheckbox, BFormSelect } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import captureOptions from "@/libs/capture-Options"

    export default {
        directives: {
            Ripple
        },
        components: {
            BRow, BCol, BFormInput, BFormGroup, BFormCheckbox, BFormSelect,
        },
        name: 'BarSetting',
        data() {
            return {}
        },
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        setup() {
            const { barPlacementOptions, barSizeOptions, barDisplayOptions, animationEffectOptions } = captureOptions();
            return {
                barPlacementOptions, barSizeOptions, barDisplayOptions, animationEffectOptions
            }
        },
        computed: {
            /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
            selectedValues() {
                if (this.prop_siteSettingArray) {
                    return this.prop_siteSettingArray;
                } else {
                    return [];
                }
            },

            /* GETS THE bar animate VALUE USING THE selectedValues(). */
            selectedValuebarAnimate: {
                get() {
                    return this.selectedValues.barAnimate == 1 ? true : false;
                },
                set(newValue) {
                    this.selectedValues.barAnimate = newValue == true ? 1 : 0;
                }
            },

            /* GETS THE wiggle Button VALUE USING THE selectedValues(). */
            selectedValuewiggleButton: {
                get() {
                    return this.selectedValues.wiggleButton == 1 ? true : false;
                },
                set(newValue) {
                    this.selectedValues.wiggleButton = newValue == true ? 1 : 0;
                }
            },

            /* GETS THE allow hidbar VALUE USING THE selectedValues(). */
            selectedValueAllowHideBar: {
                get() {
                    return this.selectedValues.allowHideBar == 1 ? true : false;
                },
                set(newValue) {
                    this.selectedValues.allowHideBar = newValue == true ? 1 : 0;
                }
            },

            /* GETS THE Pushes page down VALUE USING THE selectedValues(). */
            selectedValuePushesPageDown: {
                get() {
                    return this.selectedValues.pushesPageDown == 1 ? true : false;
                },
                set(newValue) {
                    this.selectedValues.pushesPageDown = newValue == true ? 1 : 0;
                }
            },

            /* GETS THE Pushes Remains In Place VALUE USING THE selectedValues(). */
            selectedValueRemainsInPlace: {
                get() {
                    return this.selectedValues.remainsInPlace == 1 ? true : false;
                },
                set(newValue) {
                    this.selectedValues.remainsInPlace = newValue == true ? 1 : 0;
                }
            },
        },
        created() {
            if(this.prop_siteSettingArray != undefined){
                if (_.isEmpty(this.prop_siteSettingArray.barAnimate) || this.prop_siteSettingArray.barAnimate == 0) {
                    this.prop_siteSettingArray.barAnimate = true;
                }
                if (_.isEmpty(this.prop_siteSettingArray.pushesPageDown) || this.prop_siteSettingArray.pushesPageDown == 0) {
                    this.prop_siteSettingArray.pushesPageDown = true;
                }
                if (_.isEmpty(this.prop_siteSettingArray.remainsInPlace) || this.prop_siteSettingArray.remainsInPlace == 0) {
                    this.prop_siteSettingArray.remainsInPlace = true;
                }
                if (_.isEmpty(this.prop_siteSettingArray.barPlacement)) {
                    this.prop_siteSettingArray.barPlacement = 'top';
                }
                if (_.isEmpty(this.prop_siteSettingArray.barSize)) {
                    this.prop_siteSettingArray.barSize = 'large';
                }
                if (_.isEmpty(this.prop_siteSettingArray.barDisplay) || this.prop_siteSettingArray.barDisplay == 0) {
                    this.prop_siteSettingArray.barDisplay = '1';
                }
                if (_.isEmpty(this.prop_siteSettingArray.animation_effect)) {
                    this.prop_siteSettingArray.animation_effect = 'null';
                }
            }
        },
    }
</script>